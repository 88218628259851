body {
  margin: 0;
  font-family: 'Assistant', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}

h1 {
  font-family: BeautifulPeople, serif;
  font-size: 3.5rem;
  letter-spacing: 6px;
  margin: 0;
}

h2 {
  letter-spacing: 2px;
  margin: 0;
}

p {
  font-size: 1.1rem;
  letter-spacing: 1px;
  line-height: 1.4rem;
}

@font-face {
  font-family: BeautifulPeople;
  src: url('./assets/fonts/beautiful_people.ttf') format('truetype');
}
