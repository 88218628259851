:root {
  --general-width: 520px;
  --general-border-radius: 14px;
}

.suspense-container {
  background-color: #f0f0f0;
}

.container[data-isnight='true'] {
  animation: fadeInAnimation ease 2s;

  background: url(../assets/backgrounds/zeezicht_2.webp) no-repeat center center
    fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.container[data-isnight='false'] {
  animation: fadeInAnimation ease 2s;

  background: url(../assets/backgrounds/sean-oulashin-KMn4VEeEPR8-unsplash.webp)
    no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.block-container {
  padding: 8rem 0 0 0;
  /*opacity: 0; disabled as this caused blur to disappear */
  /*animation: show 2s 0.8s forwards;disabled as this caused blur to disappear*/
}

.block-container-small {
  padding: 5rem 0 5rem 0;
  /*opacity: 0;disabled as this caused blur to disappear*/
  /*animation: show 2s 0.8s forwards;disabled as this caused blur to disappear*/
}

.tree {
  width: 100%;
  max-width: var(--general-width);
  box-shadow: 0 6px 25px 0 rgba(0, 0, 0, 0.2);
  border-radius: var(--general-border-radius);
}

.tree[data-isnight='true'] {
  filter: brightness(70%);
}

.image-container {
  position: relative;
  text-align: center;
}

.item-container {
  display: flex;
  justify-content: center;
}

.title-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.title {
  text-align: center;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.subtitle {
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  letter-spacing: 1px;
}

.title-names {
  color: white;
  font-family: BeautifulPeople, serif;
  font-size: 1.5rem;
  letter-spacing: 4px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin: 1rem 0;
}

.error-message {
  width: 100%;
  color: white;
  text-align: center;
  max-width: var(--general-width);
  margin-bottom: 3rem;
  text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.7);
}

.card-content {
  color: white;
  text-align: center;
  width: 70%;
  max-width: var(--general-width);
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.7);
  padding: 2rem;
}

.card {
  width: 100%;
  max-width: var(--general-width);
  box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.3);
  background: rgba(39, 39, 39, 0.1);
  border-radius: var(--general-border-radius);
  backdrop-filter: blur(8px);
  box-sizing: border-box; /* To make sure max-width also listens to padding on child (description) */
}

/* Form related styles. */

form {
  margin: 1rem 0 8rem 0;
}

.form-description {
  text-align: start;
  margin-bottom: 2rem;
}

/* TextField */

.input-field {
  position: relative;
  border-bottom: 2px solid #ccc;
  margin: 24px 0;
}

.input-field label {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  color: #fff;
  font-size: 16px;
  pointer-events: none;
  transition: 0.15s ease;
}

.input-field input {
  width: 100%;
  height: 40px;
  background: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  color: #fff;
}

.input-field input:focus ~ label,
.input-field input:user-invalid ~ label,
.input-field input:valid ~ label {
  font-size: 0.8rem;
  top: 10px;
  transform: translateY(-120%);
}

/* TextArea */

.input-field textarea {
  width: 100%;
  height: 60px;
  min-height: 60px;
  min-width: 100%;
  max-width: 100%;
  background: transparent;
  border: none;
  outline: none;
  font-size: 16px;
  color: #fff;
  margin-top: 10px;
  letter-spacing: -1px;
}

.input-field textarea:focus ~ label,
.input-field textarea:user-valid ~ label {
  font-size: 0.8rem;
  top: 10px;
  transform: translateY(-120%);
}
.input-field textarea::-webkit-scrollbar {
  display: none;
}

/* Radio buttons */

.radio-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-bottom: 8px;
}

.radio-group > *:nth-child(2) {
  margin: 0 12px;
}

.radio {
  display: flex;
  align-items: center;
  color: #ccc;
  width: 100%;
  cursor: pointer;
}

.radio input[type='radio'] {
  display: none;
}

.radio-custom {
  width: 100%;
  height: 48px;
  border-radius: var(--general-border-radius);
  box-shadow: 0 5px 10px 4px rgba(0, 0, 0, 0.3);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: white;

  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  transition: all 0.2s ease-out;
}

.radio input[type='radio']:checked + .radio-custom {
  box-shadow: inset 0 5px 10px 4px rgba(0, 0, 0, 0.3);
  background-color: #64afb4;
}

.radio:hover .radio-custom {
  box-shadow: inset 0 8px 8px 0 rgba(0, 0, 0, 0.3);
}

.radio-validation {
  color: #e37d7d;
  font-weight: bold;
}

/* Button */

.button-custom {
  display: flex;
  align-items: center;
  color: white;
  width: 100%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  height: 48px;
  border-radius: var(--general-border-radius);
  box-shadow: 0 5px 10px 4px rgba(0, 0, 0, 0.3);
  position: relative;
  justify-content: center;
  font-weight: bold;

  transition: background-color 1.5s ease-in-out;
}

.button-custom:hover {
  box-shadow: inset 0 8px 8px 0 rgba(0, 0, 0, 0.3);
}

.button-custom:focus,
.button-custom:active {
  box-shadow: inset 0 5px 10px 4px rgba(0, 0, 0, 0.3);
  background-color: #64afb4;
  transition: none;
}

.spinner {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}

.spinner::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  inset: 0;
  border-radius: 50%;
  border: 5px solid #fff;
  animation: prixClipFix 2s linear infinite;
}

/* Responsiveness */

@media (max-width: 394px) {
  .title[data-isnight='false'] {
    font-size: 3rem;
  }
  .subtitle {
    font-size: 1rem;
  }
  .title-names {
    font-size: 1.4rem;
  }
}

@media (max-width: 440px) {
  .title[data-isnight='true'] {
    font-size: 2.3rem;
  }
}

@media (max-width: 520px) {
  .tree {
    border-radius: 0;
  }
  .card {
    border-radius: 0;
  }
}

/* Animations */

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes show {
  to {
    opacity: 1;
  }
}

/* Spinner */
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
/* Spinner */
@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}
